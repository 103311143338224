import React, {useCallback, useContext, useEffect, useState} from "react";
import {navigate} from "gatsby";
import {LoadingSpinner, PageLayout} from "../components/components";
import {GET_ALL_GAMES_QUERIES, getAllGames} from "../services/firebase";
import {first} from "rxjs/operators";
import {AppAlertsContext} from "../context/app-alerts-context";

const redirectAfterErrorSeconds = 4;

//
// This page will redirect to the dashboard
//
const RedirectToDashboardPage = () => {
  const {popError} = useContext(AppAlertsContext);
  const [showError, setShowError] = useState(false);

  const routeToGame = useCallback((game) => {
    const params = new URLSearchParams({
      gameID: game.id,
    });
    navigate(`/clue-master/game?${params.toString()}`);
  }, []);


  useEffect(async () => {
    const [primaryGame] = await getAllGames([GET_ALL_GAMES_QUERIES.PRIMARY_GAME]).pipe(first()).toPromise();
    if (primaryGame) {
      routeToGame(primaryGame);
    } else {
      // Fallback to an active game
      const [activeGame] = await getAllGames([GET_ALL_GAMES_QUERIES.ONE_ACTIVE_GAME]).pipe(first()).toPromise();
      if (activeGame) {
        routeToGame(activeGame);
      } else {
        popError(`No active games. You will be redirected to the Clue Master dashboard in ${redirectAfterErrorSeconds} seconds.`);
        setShowError(true);
      }
    }
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      if (showError) {
        navigate('/clue-master/games/')
      }
    }, redirectAfterErrorSeconds * 1000);

    return () => clearTimeout(t);

  }, [showError]);

  return (
    <PageLayout>
      <LoadingSpinner isLoading={!showError}/>
    </PageLayout>
  );
};

export default RedirectToDashboardPage;
